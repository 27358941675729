$primary-color: rgb(31, 41, 55);
$light-color: rgba(0,0,0,.5);
$accent-color: #635cff;
.root {
    background-color: rgba(0, 0, 100, 0);
    height: 100%;
    width: 60%;
    display: inline-block;
    vertical-align: top;
    max-width: 500px;
}
.backArrow {
    display: inline-block;
    width: 30px;
    vertical-align: middle;
    margin-top: 9px;
    opacity: 0.6;
    transition: 0.3s;
    text-align: left;
}
.backText {
    display: inline-block;
    width: calc(100% - 30px);
    vertical-align: middle;
    text-align: left;
    font-weight: 400;
    font-size: 0.8rem;
    transition: 0.3s;
    margin-left: 0px;
    margin-top: 0px;
    color: $light-color;
}
.amount {
    font-size: 28px;
    text-align: left;
    margin-top: 18px;
    font-weight: 500;
    color: $primary-color;
}
.amountLabel {
    text-align: left;
    margin-top: 14px;
    font-weight: 300;
    opacity: 1;
    font-size: 18px;
    color: $light-color;
    margin-bottom: 0px;
}
.paymentFrequency {
    text-align: left;
    margin-top: -20px;
    font-weight: 300;
    font-size: 14px;
    text-shadow:/* White glow */ 0 0 7px #fff4, 0 0 10px #fff4, 0 0 21px #fff4;
    color: $light-color;
}
.backBox {
    margin-top: calc(10vh - 8px);
    display: block;
    cursor: pointer;
    width: fit-content;
    height: 50px;
    margin-bottom: 0px;
    margin-left: 0px;
    opacity: 0.6;
    transition: .3s;
    text-align: left;
    &:hover {
        opacity: 1;
        .backText,
        .backArrow {
            opacity: 1 !important;
            color: #222;
        }
    }
}
.image {
    width: 220%;
    margin-left: -160%;
    opacity: 0.8;
    margin-top: 40px;
}
@media (max-width: 1000px) {
    .image {
        display: none;
    }
    .root {
        width: 500px;
        max-width: calc(100% - 60px);
        box-shadow: none;
        text-align: left;
    }
    .backBox {
        position: absolute;
        margin-top: 40px;
        margin-bottom: 0px;
        height: 40px;
    }
    .backText {
        display: none;
    }
    .amountLabel {
        margin-top: 30px;
        font-weight: 200;
        opacity: 1;
        font-size: 14px;
        margin-bottom: 0px;
        margin-left: 60px;
    }
    .amount {
        font-size: 26px;
        margin-top: 12px;
        font-weight: 500;
        display: inline-block;
        margin-left: 60px;
    }
    .paymentFrequency {
        text-align: left;
        margin-top: -30px;
        display: inline-block;
        margin-left: 10px;
    }
}
