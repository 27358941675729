$primary-color: rgb(31, 41, 55);
.root {
    background-color: rgba(0, 0, 100, 0);
    height: 100%;
    width: 60%;
    display: inline-block;
    vertical-align: top;
    max-width: 500px;
}
.form {
    width: 100%;
    margin-top: 10vh;
    height: 80%;
}
.title {
    font-weight: 500;
    text-align: left;
    color: $primary-color;
    margin-bottom: 40px;
    font-size: 1.2rem;
}
@media (max-width: 1000px) {
    .root {
        width: 500px;
        max-width: calc(100% - 60px);
        min-width: 100px;
    }
}
.checkIcon {
    display: block;
    margin: 35vh auto;
    margin-bottom: 40px;
    animation: fadeIn 2s;
}
@keyframes fadeIn {
    0% {
        opacity: 0;
        margin-top: -10px;
    }
    100% {
        opacity: 1;
        margin-top: 0px;
    }
}
