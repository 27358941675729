@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');html {
  padding: 0px;
  margin: 0px;
}
* {
  font-family: "Inter";
}
body {
  margin: 0px;
}
.app {
  text-align: center;
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ". .";
  height: 100vh;
  padding: 0px;
  animation: fadeIn 2s;
}
.leftBackground {
  background-color: #fafafa;
  height: 100vh;
  // box-shadow: rgba(0, 0, 0, 0.15) 0px -5px 26px -8px inset;
  border-right: 1px solid rgba(0,0,0,0.1);
  background-image: linear-gradient(-45deg, rgba(70, 34, 255, 0.1), rgba(0, 93, 255, 0.05))
}
.rightBackground {
  background-color: white;
  height: 100vh;
}
.appContent {
  height: 100%;
  width: 100%;
  margin: auto;
  z-index: 1;
  position: absolute;
  top: 0px;
  display: block;
}
.formContainer {
  display: block;
  height: 100vh;
  padding: 0px;
  max-width: 100%;
  width: 1000px;
  margin: auto;
}
@media (max-width: 1000px) {
  .app {
    display: block;
    padding-top: 100px;
  }
  .leftBackground {
    height: 120px;
    width: 100%;
    box-shadow: none;
    position: fixed;
    top: 0px;
    background-color: rgba(255,255,255,0.7);
    backdrop-filter: blur(5px) saturate(2);
    box-shadow: 0 0 1.875rem 0 rgba(26, 46, 68, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% { 
    opacity: 0; 
    margin-top: -10px;
  }
  100% { 
    opacity: 1;
    margin-top: 0px;
  }
}