$primary-color: rgb(31, 41, 55);
$light-color: rgba(0,0,0,.5);
$font-size: 0.9rem;
.label {
    color: $light-color;
    font-size: 13px;
    font-weight: 400;
    text-align: left;
    display: block;
    margin-top: 32px;
}
.textInput {
    color: $primary-color;
    transition: 0.3s;
    display: block;
    height: 20px;
    width: calc(100% - 22px);
    padding: 10px;
    padding-left: 12px;
    outline: none;
    border: solid 1px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    margin-top: 10px;
    font-size: $font-size;
    &:focus {
        border-color: rgba(0, 0, 0, 0.7);
    }
}
.cardInput {
    color: $primary-color;
    position: relative;
    transition: 0.3s;
    display: block;
    height: 40px;
    width: calc(100% - 56px);
    padding: 0px;
    padding-left: 46px;
    padding-right: 10px;
    outline: none;
    border: solid 1px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    margin-top: 10px;
    font-size: $font-size;
    &:focus {
        z-index: 1;
        border-color: rgba(0, 0, 0, 0.7);
    }
    &::placeholder {
        color: rgba(0, 0, 0, 0.2);
    }
}
.nameInput {
    color: $primary-color;
    position: relative;
    transition: 0.3s;
    display: block;
    height: 40px;
    width: calc(100% - 20px);
    padding: 0px;
    padding-left: 10px;
    padding-right: 10px;
    outline: none;
    border: solid 1px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    margin-top: 10px;
    font-size: $font-size;
    &:focus {
        z-index: 1;
        border-color: rgba(0, 0, 0, 0.7);
    }
    &::placeholder {
        color: rgba(0, 0, 0, 0.2);
    }
}
.dateInput {
    color: $primary-color;
    position: relative;
    transition: 0.3s;
    display: inline-block;
    height: 40px;
    width: calc(50% - 23px);
    padding: 0px;
    padding-left: 12px;
    padding-right: 10px;
    outline: none;
    border: solid 1px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    margin: 0px;
    margin-top: -1px;
    font-size: $font-size;
    &:focus {
        z-index: 1;
        border-color: rgba(0, 0, 0, 0.7);
    }
    &::placeholder {
        color: rgba(0, 0, 0, 0.2);
    }
}
.cvcInput {
    color: $primary-color;
    position: relative;
    transition: 0.3s;
    display: inline-block;
    height: 20px;
    width: calc(50% - 22px);
    padding: 10px;
    padding-left: 12px;
    outline: none;
    border: solid 1px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    margin: 0px;
    margin-top: -1px;
    font-size: $font-size;
    transform: translateX(2px);
    margin-left: -3px;
    &:focus {
        z-index: 1;
        border-color: rgba(0, 0, 0, 0.7);
    }
    &::placeholder {
        color: rgba(0, 0, 0, 0.2);
    }
}
.emailInput {
    color: $primary-color;
    position: relative;
    transition: 0.3s;
    display: inline-block;
    height: 20px;
    width: calc(60% - 23px);
    padding: 10px;
    padding-left: 12px;
    outline: none;
    border: solid 1px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    margin: 0px;
    margin-top: -1px;
    font-size: $font-size;
    &:focus {
        z-index: 1;
        border-color: rgba(0, 0, 0, 0.7);
    }
    &::placeholder {
        color: rgba(0, 0, 0, 0.2);
    }
}
.dniInput {
    color: $primary-color;
    position: relative;
    transition: 0.3s;
    display: inline-block;
    height: 20px;
    width: calc(40% - 22px);
    padding: 10px;
    padding-left: 12px;
    outline: none;
    border: solid 1px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    margin: 0px;
    margin-top: -1px;
    font-size: $font-size;
    transform: translateX(2px);
    margin-left: -3px;
    &:focus {
        z-index: 1;
        border-color: rgba(0, 0, 0, 0.7);
    }
    &::placeholder {
        color: rgba(0, 0, 0, 0.2);
    }
}
.optionsContainer {
    text-align: left;
}
.optionBox {
    color: $primary-color;
    margin-top: 10px;
    margin-right: 6px;
    margin-left: 0px;
    transition: border 0.3s;
    display: inline-block;
    height: 20px;
    width: 100px;
    padding: 10px;
    border: solid 1px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    font-size: $font-size;
    align-content: left;
    cursor: pointer;
    text-align: center;
    &:hover {
        border: solid 1px rgba(0, 0, 0, 0.7);
    }
}
.selectedOptionBox {
    color: black;
    margin-top: 10px;
    margin-right: 6px;
    margin-left: 0px;
    transition: border 0.3s;
    display: inline-block;
    height: 20px;
    width: 100px;
    padding: 10px;
    border: solid 1px rgba(0, 0, 0, 1);
    border-radius: 6px;
    font-size: $font-size;
    align-content: left;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.03);
    &:hover {
        border: solid 1px rgba(0, 0, 0, 0.7);
    }
}
button {
    transition: .3s;
    display: block;
    background-color: $primary-color;
    outline: none;
    border: none;
    color: white;
    padding: 10px;
    width: 100%;
    margin-top: 64px;
    border-radius: 6px;
    font-size: $font-size;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
    &:disabled {
        opacity: 0.4;
        color: lightgray;
        cursor: default;
    }
}
.cardErrorMsg {
    position: absolute;
    color: rgba(255,0,0,0.7);
    font-size: 12px;
    text-align: left;
    margin: 10px;
    display: block;
    top: 84px;
    left: 2px;
    animation: fadeInCard 0.5s;
    @keyframes fadeInCard {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}
.cardImage {
    position: absolute;
    top: 13px;
    left: 13px;
    z-index: 2;
    pointer-events: none;
}
.cardInputContainer {
    position: relative;
    margin-bottom: 10px;
    transition: .3s;
}
.cardInputContainerWithError {
    position: relative;
    margin-bottom: 60px;
    transition: .3s;
}